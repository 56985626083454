.login:hover {
  background-color: green;
}


.link {
  cursor: pointer;
  color: grey;
}
.link:hover{
  color: #000;
}