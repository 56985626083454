/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  font-family: "Poppins", sans-serif;

  padding: 0;
  box-sizing: border-box;
  background-color: #f1f4f6;
}

h1,
h2,
h3,
h4,
h5,
h5,
a,
ul,
li {
  margin: 0;
  padding: 0;
}

.flex-even {
  flex: 1 !important;
} */

.excelRowContainer {
  overflow-x: scroll;
  overflow-y: hidden;
}

.excelRowContainer::-webkit-scrollbar {
  height: 7px;
  border-radius: 25%;
  overflow: hidden;
}

.excelRowContainer::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 25%;
  overflow: hidden;
}

.excelRowContainer::-webkit-scrollbar-thumb {
  background: green;
}

.excelRowContainer::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.excelRowContainer::-webkit-scrollbar-button {
  display: none;
}

.predefinedColumnsContainer {
  overflow-x: scroll;
  overflow-y: hidden;
}
.predefinedColumnsContainer::-webkit-scrollbar {
  height: 7px;
  border-radius: 25%;
  overflow: hidden;
}

.predefinedColumnsContainer::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 25%;
  overflow: hidden;
}

.predefinedColumnsContainer::-webkit-scrollbar-thumb {
  background: green;
}

.predefinedColumnsContainer::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.predefinedColumnsContainer::-webkit-scrollbar-button {
  display: none;
}
